import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Snackbar, IconButton, Typography, Stack, Tooltip, Textarea } from '@mui/joy';
import { isMobile, isBrowser } from 'react-device-detect';
import L from 'leaflet'
import {  Marker, useMapEvents, useMap } from 'react-leaflet'

import { createNewPin, fetchPinList } from './pinFetching';
import { PinCreationModal } from '../Modals/pinModals';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext';

import orangeLocation from '../../assets/orange-location.png'
import addLocation from '../../assets/addLocation.png'
import close from '../../assets/close.png'

import focus1 from '../../assets/location/focus.png'

import '../../App.css'

const customIconOrange = new L.Icon({
  iconUrl: orangeLocation,
  iconSize: [30, 30], // size of the icon
  iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
});

const NewEntryModal = ({ open, handleCloseSnack, coordinatesInput, setDisableZoom, handleNewLocation }) => {
  
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [openCreate, setOpenCreate] = useState(false); // Control the modal with this state
  const [titleError, setTitleError] = useState('');
  const { addPin, setPinView  } = usePinListPosition();

  const handleCreateOpen = () => {
    setOpenCreate(true); // Opens the modal
    handleCloseSnack();  // Close Snackbar immediately
  };

  const handleCreateClose = () => {
    setOpenCreate(false); // Closes the modal
    handleNewLocation()
    setPinView(true)
    //setCoordinates({ lat: '', lng: '' });
    setTitle('');
    setDescription('');
  };

  const handlePinConfirm = async (event) => {
    setTitleError('');

    // Validate title field
    if (!title) {
      setTitleError('Title is required.');
      return; // Prevent form submission
    }
  
    try {
      // Call createNewPin and wait for the result
      console.log('Submitting new pin...');
      await createNewPin(coordinatesInput, title, description);

      // Fetch the updated pin list after creating a new pin
      const { data } = await fetchPinList();
      console.log('Fetched pins', data.listEntries.items);

      // Iterate over the fetched pins and add them
      data.listEntries.items.forEach(({ lat, lng, title, description, id }) => {
        addPin(lat, lng, title, description, id);
      });

      // Reset the form fields or any other necessary state
      setOpenCreate(true);
      // Close the modal or show success message
      // handleClose();  // Assuming this closes the modal
      
    } catch (error) {
      console.error('Error creating pin:', error);
    }
  };


  

  return (
    <>
  {/* The card component will be displayed instead of Snackbar */}
  {(open || !openCreate) && (
    <Box
    onMouseEnter={() => setDisableZoom(true)}
    onMouseLeave={() => setDisableZoom(false)}
      sx={{
        position: 'fixed', // To make it appear like a modal
        bottom: '25px',
        left: isMobile? '':'10px',
        minWidth: 300,
        width:isMobile? '95%': '',
        bgcolor: 'white',
        borderRadius: 5,
        boxShadow: 3,
        p: 3,
        zIndex: 1006, // Ensures the card appears on top of other content
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography level="h3" textAlign="center">
        Create new Pin
      </Typography>
      <Stack spacing={1} mt={2} width={'90%'}>
        {coordinatesInput && (
          <Stack spacing={1} direction="row">
            <Typography>Coordinates:</Typography>
            <Tooltip title={coordinatesInput.lat} variant="solid" placement="top">
              <Typography>{coordinatesInput.lat.toFixed(3)}</Typography>
            </Tooltip>
            <Tooltip title={coordinatesInput.lng} variant="solid" placement="top">
              <Typography>{coordinatesInput.lng.toFixed(3)}</Typography>
            </Tooltip>
          </Stack>
        )}

        <Input
          label="Title"
          margin="normal"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required 
        />
        {titleError && <Typography style={{ color: 'red', fontSize:'12px', marginTop:0 }}>{titleError}</Typography>}
        <Textarea
          label="Description"
          multiline
          minRows={2}
          rows={4}
          margin="normal"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Stack>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={handlePinConfirm} style={{ marginLeft: 10 }}>
          Confirm
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleCloseSnack} style={{ marginLeft: 10 }}>
          Cancel
        </Button>
      </Box>
    </Box>
  )}

{openCreate && (
        <PinCreationModal handleCreateClose={handleCreateClose}  openCreate={openCreate} />
      )}
    </>
  );
};


const EntryClick = ({setDisableZoom, handleNewLocation, selectedPosition, setSelectedPosition}) => {

  

  useMapEvents({
    moveend: (e) => {
      if (isMobile) {
        const center = e.target.getCenter();
        console.log('Center:', center);
        setSelectedPosition(center);
      }
    },
    click: (e) => {
      if (!isMobile) {
        const clickedPosition = e.latlng;
        console.log('Clicked coordinates:', clickedPosition);
  
        setSelectedPosition(clickedPosition);
      }
    }
  });

    const handleCloseSnack= () => {
      setSelectedPosition()
    }

    return (
      <>
                {selectedPosition/*.lat*/ !=undefined  && (
                  <>
            <Marker onClick={()=>console.log('hello')} position={[selectedPosition.lat, selectedPosition.lng]} icon={customIconOrange} />
          <NewEntryModal coordinatesInput={selectedPosition} open={selectedPosition} setSelectedPosition={setSelectedPosition} handleCloseSnack={handleCloseSnack} setDisableZoom={setDisableZoom} handleNewLocation={handleNewLocation}/>
                  </>
          )}
      </>
    )
}

const PinCreationToggle = ({setDisableZoom}) => {
  const [newLocation, setNewLocation] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState()
  const [cursorChange, setCursorChange] = useState(false)

  //const map = useMap()
  //const mapContainer = map.getContainer();

  const handleNewLocation = () =>{
    setNewLocation(!newLocation)
    //setSelectedPosition(null)
    //document.getElementById('map').style.cursor = 'crosshair'

    //document.body.style.cursor = 'crosshair';
  }
  const map = useMap();
  useEffect(() => {
    // Change cursor on the body and the map container
    if (newLocation) {
      document.body.style.cursor = 'crosshair'; // Global cursor change
      map.getContainer().style.cursor = 'crosshair'; // Change cursor on the map
    } else {
      document.body.style.cursor = ''; // Revert to default when not placing pins
      map.getContainer().style.cursor = ''; // Revert map cursor as well
    }

    // Clean up: reset the cursor when component unmounts or newLocation changes
    return () => {
      document.body.style.cursor = ''; 
      map.getContainer().style.cursor = '';
    };
  }, [newLocation, map]);

  useMapEvents({
    click: (e) => {
      if (isMobile /*&& newLocation*/) {
        const center = e.target.getCenter();
        console.log({center})
        setSelectedPosition(center); // Set position based on map center on moveend
        
      }
    },})

  const handleMouseEnter = () => {
    setDisableZoom(true);
    setCursorChange(true)
  };
  const handleMouseLeave = () => {
    setDisableZoom(false);
    setCursorChange(true)
  };

  return (
    <>
    <Box
    onMouseEnter={handleMouseEnter} 
    onMouseLeave={handleMouseLeave} 
    >

    <Tooltip title={newLocation ?'':'Add Pin'} variant='solid' placement='right'>

    <Button  
  variant='solid' 
  sx={{
    position: 'fixed', 
    top: '150px', 
    left: '10px', 
    zIndex: 1006, 
    width: '30px', 
    height: '30px', 
    marginTop: '10px', 
    border: '1px solid grey',
    backgroundColor: 'white', 
    '&:hover': { 
      backgroundColor: 'rgba(255, 255, 255, 0.8)' 
    }
  }} 
  onClick={handleNewLocation}
>
  <img src={newLocation ? close : addLocation} style={{ height: '15px' }}/>
</Button>
        </Tooltip>

    </Box>
    {newLocation
    ? 
    <>
    <EntryClick setDisableZoom={setDisableZoom} handleNewLocation={handleNewLocation} selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition}/>
    </>

      :
      <></>
    }
    {isMobile
    ? 
    <>
    <img className='crosshair' src={focus1}/>
    </>
    :
    <>
    </>
    }
      </>
  )
}


export {NewEntryModal, EntryClick, PinCreationToggle};
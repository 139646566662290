import React,{ useState, useEffect, useRef} from 'react';
import { ThemeProvider, createTheme, Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {components, myTheme} from './utils/customLoginScreen.js'

import { useLocation, useNavigate, Route, Routes } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';

import Box from '@mui/joy/Box';


import NavBar from './components/Navbar.js';
import {BlankMapBody, BlackcombMapBody, SpearheadMapBody, WhistlerMapBody,WB512300, WB512450, WB1024300, WB1024450,} from './components/mapBody.js';
import Footer from './utils/Footer.js'
import SplashPage from './components/SplashPage.js';
import AdminAccess from './components/AdminAccess.js';
import { usePosition } from './utils/MapUtils/MapContext/PositionContext.js';
import UpdateNotification from './utils/Modals/updateNotification.js';

import './App.css';



function App() {

  const[showSplash, setShowSplash] = useState(true)

  const [currentUserGroup, setCurrentUserGroup] = useState()
  const [currentUserName, setCurrentUserName] = useState()

  const [currentUserArray, setCurrentUserArray] = useState([])

  const [currentMap, setCurrentMap] = useState('wbcombo')

  const { setPosition } = usePosition();

  // sets current map
  const handleSetCurrentMap = (map) => {
    setCurrentMap(map)
    setPosition({ latitude: null, longitude: null, zoom: null })
  }

  const location = useLocation();
  const navigate = useNavigate()

  //=============================below is splashscreen logic========================
  //if location changes to base, will show the splashscreen
  useEffect(() => {
    // Check if the path is the homepage
    const isHomepage = location.pathname === '/';
  
    // If it's the homepage, show the SplashPage
    if (isHomepage) {
      setShowSplash(true);
    } else {
      setShowSplash(false);
    }
  }, [location.pathname]);

  // below sets the splashpage to end its displya
  const handleSplashEnd = () => {
    setShowSplash(false);
    navigate('/home')

  };

  // below for determining admin access
  const handleUserLoad = async () => {
    try {
      const session = await fetchAuthSession();
      //console.log('session',session)


      //console.log("cognito groups", session.tokens.idToken.payload["cognito:groups"])
      setCurrentUserArray(session.tokens.idToken.payload["cognito:groups"])
      setCurrentUserName(session.tokens.idToken.payload.given_name + ' ' + session.tokens.idToken.payload.family_name)
      console.log('Current user array:', session.tokens.idToken.payload["cognito:groups"]);
      
      
  } catch(error) {
      console.log('Error retrieving user data: ', error)
  }
}

useEffect(() => {
    handleUserLoad()
  }, [])


  return (
    <>
    {showSplash && <SplashPage onSplashEnd={handleSplashEnd} />}
    {!showSplash && (
      <ThemeProvider theme={myTheme}>
    <Authenticator components={components} className='authenticator-background' style={{borderRadius:'10px'}} >
      <Box className={location.pathname === '/login' ? 'login-background' : ''}>

        <NavBar currentUserArray={currentUserArray} handleSetCurrentMap={handleSetCurrentMap} currentMap={currentMap}/>

        <UpdateNotification/>
        {location.pathname === '/home'
          ?
        currentMap === 'blankmap' ? <BlankMapBody handleSetCurrentMap={handleSetCurrentMap}/>:
        currentMap === 'blackcomb' ? <BlackcombMapBody />:
        currentMap === 'spearhead' ? <SpearheadMapBody/>:
        currentMap === 'whistler' ? <WhistlerMapBody/>:
        currentMap === 'wbcombo' ? <WB512450/>:
        currentMap === 'wb512300' ? <WB512300/>:
        currentMap === 'wb512450' ? <WB512450/>:
        currentMap === 'wb1024300' ? <WB1024300/>:
        currentMap === 'wb1024450' ? <WB1024450/>:
        <BlankMapBody handleSetCurrentMap={handleSetCurrentMap}/>

        :
        <AdminAccess currentUserArray={currentUserArray}/>
      }
        <Footer/>
      </Box>

      <Routes>
        <Route path='/' element={showSplash && <SplashPage onSplashEnd={handleSplashEnd} />}/>
        <Route path ='/home' element ={<></>}/>
        <Route path ='/admin' element ={<></>}/>

      </Routes>
    </Authenticator>
    </ThemeProvider>
    )}
    </>
  );
}

export default App;

import { signOut } from 'aws-amplify/auth';


async function handleSignOut() {
  try {
    await signOut();
    window.location.href = '/'
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export {handleSignOut}
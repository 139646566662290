import React, { createContext, useState, useContext } from 'react';

// Create a context for the pin list positions
const PinListPositionContext = createContext();

// Create a provider component
export const PinListPositionProvider = ({ children }) => {
  // State is now an array of objects
  const [pinView, setPinView]= useState(false)
  const [pinListPositions, setPinListPositions] = useState([]);
  const [focusedPin, setFocusedPin] = useState(null)

  // Function to add a new pin to the list
  const addPin = (latitude, longitude, title, description, id) => {
    setPinListPositions((prevPins) => [
      ...prevPins,
      { latitude, longitude, title, description, id },
    ]);
  };

  // Function to remove a pin by title (or some other unique property)
  const removePin = (title) => {
    setPinListPositions((prevPins) =>
      prevPins.filter((pin) => pin.title !== title)
    );
  };

  return (
    <PinListPositionContext.Provider value={{ pinListPositions, addPin, removePin, focusedPin, setFocusedPin, pinView, setPinView }}>
      {children}
    </PinListPositionContext.Provider>
  );
};

// Custom hook to use the pin list position context
export const usePinListPosition = () => {
  return useContext(PinListPositionContext);
};
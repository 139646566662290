import React, {useState} from 'react';

import { useNavigate } from 'react-router-dom';
import { isMobile, isBrowser } from 'react-device-detect';
import { handleSignOut } from '../utils/SignOut';
import PinListDisplay from '../utils/navigationUtils/pinSelectionList';
import { usePosition } from '../utils/MapUtils/MapContext/PositionContext';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Drawer from '@mui/joy/Drawer';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Divider from '@mui/joy/Divider';
import ModalClose from '@mui/joy/ModalClose';
import { ToggleButtonGroup, Typography, Snackbar  } from '@mui/joy';

import largeLogo from '../assets/ULLRLogo.svg'
import smallLogo from '../assets/ULLRLogo-small.svg'
import hamburger from '../assets/hamburger.png'
import refresh from '../assets/refresh.png'
import leftArrow from '../assets/leftArrow.png'
import firstAid from '../assets/firstaid.png'
import '../css/navbar.css'

export default function Navbar( {currentUserArray, handleSetCurrentMap, currentMap} ) {
    const [leftOpen, setLeftOpen ] = useState(false);
    const [openFA, setOpenFA ] = useState(false);
    const [mapOpen, setMapOpen ] = useState(false)
    const [pinOpen, setPinOpen ] = useState(false)
    const {userPosition, setUserPosition} = usePosition()
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const returnToMainNav = () => {
        setLeftOpen(true)
        setOpenFA(false)
        setMapOpen(false)
        setPinOpen(false)
    }

    const handleAdminNav = () => {
        setLeftOpen(false)
        setOpenFA(false)
        navigate('/admin')
    }

    let isAdmin = currentUserArray?.currentUserArray?.includes('Admin')
    
    const handleMapChange = (map) => {

        handleSetCurrentMap(map)
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinOpen(false)
    }
    const handleMapLibraryNav = () => {
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinOpen(false)
    }

    const handlePinListNav = () => {
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinOpen(false)
    }

    const handlePinListOpen =()=>{
        isMobile
        ?
        setPinOpen(true)
        :
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinOpen(true)
    }

    const updateUserPosition = () => {
    
        if ("geolocation" in navigator) {
            setLoading(true)
            navigator.geolocation.getCurrentPosition(function (position) {
                //console.log({position})
            const newPosition = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                elevation:position.coords.altitude,
            };
            //console.log({newPosition})
            

            //if (isWithinBounds(newPosition, maxBounds)) {
                setUserPosition(newPosition);
                //console.log('position updated latitude', userPosition.latitude, 'longitude', userPosition.longitude);
                setLoading(false)
    /*
            } else {
                setActiveGPS(false);
                handleOutOfBounds();
                setLoading(false);
                
            }
    */
            });
        } else {
            console.log("Geolocation is not available in your browser.");
            setLoading(false);
        }
        };

    const [copied, setCopied] = useState(false);

    const phoneNumber = "604-935-5555";

    const handleCopy = () => {
        const textToCopy = "604-935-5555";
        
        // Use the clipboard API to copy the text
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            setCopied(true); // Optional: Set copied state to true if successful
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      };
      const handleCoordinates = () => {
        
        console.log(userPosition)
        const textCopy = userPosition.latitude.toString() + ' ' + userPosition.longitude.toString()
        // Use the clipboard API to copy the text
        navigator.clipboard.writeText(textCopy)
          .then(() => {
            setCopied(true); // Optional: Set copied state to true if successful
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      };



  return (
    <Box sx={{ display: 'flex', 

        }}>
        <Box width='100%' height='60px' 
                sx={{
                    boxShadow: '0px 5px 8px 0px rgba(64, 64, 64, 1)', // Adding drop shadow
                    backgroundColor: 'white', // Ensure background color for visibility
                    position: 'relative', // Ensure position is set
                    zIndex: 1000, // Make sure it's on top of other elements,
                }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>

                <Button variant="outlined" color="neutral"
                    style={{
                        width:'30px',
                        height:'30px',
                        marginLeft:'10px',
                        }}
                    onClick={() => setLeftOpen(true)}>
                    <img src={hamburger} alt="hamburger menu" style={{ width:'15px'}}></img>
                </Button>
                <img className='largeLogo' src={largeLogo} alt='Ullr Logo'/>

                <Button variant={openFA?'soft':"outlined"} color={openFA?'danger':"neutral"}
                    style={{
                        width:'30px',
                        height:'30px',
                        marginRight:'10px',
                        }}
                        onClick={() => setOpenFA(!openFA)}>
                    <img src={firstAid} alt="location menu" style={{ width:'15px'}}></img>
                </Button>

            </Stack>
        </Box>
        

      <Drawer size='sm' open={leftOpen} anchor='left' onClose={() => setLeftOpen(false)}>
            <Stack height='100%' justifyContent='space-between'>
            <List>
                <Stack alignContent={'left'} sx={{marginLeft:'8px'}}>
                    <Box py={0.5}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                        <img className='smallLogo' src={smallLogo} alt='Ullr Logo'/>
                        <Typography level='h5' fontWeight='bold'>{currentMap==='wbcombo'
                        ?'Whistler Blackcomb'
                        : currentMap==='spearhead' 
                        ?'Spearhead':
                        currentMap==='whistler'
                        ?'Whistler':
                        currentMap==='wb512300'
                        ?'wb512300':
                        currentMap==='wb512450'
                        ?'wb512450':
                        currentMap==='wb1024300'
                        ?'wb1024300':
                        currentMap==='wb1024450'
                        ?'wb1024450':
                        currentMap==='blankmap'
                        ?''
                        : ''}
                        </Typography>

                        </Stack>
                    </Box>
                    <ModalClose size='lg' />


                </Stack>
                <Divider />
            <ListItem key={'About Ullr'}>
                <ListItemButton>About Ullr</ListItemButton>
            </ListItem>
            <ListItem key={'Map Library'}>
                <ListItemButton onClick={() => setMapOpen(true)}>Map Library</ListItemButton>


            <Drawer 
                size='sm'
                sx={{ ml:2 /*'min(100vw, var(--Drawer-horizontalSize))'*/ }}
                open={mapOpen}
                anchor='left'
                onClose={handleMapLibraryNav}>


                <Stack alignContent={'left'} sx={{marginLeft:'8px'}}>
                    <Box py={0.5}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <img className='smallReturn' src={leftArrow} alt='return' onClick={returnToMainNav}/>
                        <Typography level='h4'>Available Maps</Typography>
                    </Stack>
                    </Box>
                    <ModalClose size='sm' />
                </Stack>
                <Divider />
                <ToggleButtonGroup
                    value={currentMap}
                    onChange={(event, newValue) => {
                        handleMapChange(newValue);
                    }}
                    sx={{marginLeft:'8px', marginTop:'8px'}}>
                        <Stack direction='column' spacing={1.5} width={'95%'} >
                        <Button value="wbcombo">Whistler Blackcomb</Button>
                        <Button value='spearhead'> Spearhead</Button>

                        <Button value="wb512300">WB 512-300</Button>
                        <Button value="wb512450">WB 512-450</Button>
                        <Button value="wb1024300">WB 1024-300</Button>
                        <Button value="wb1024450">WB 1024-450</Button>
                        </Stack>
                </ToggleButtonGroup>
            </Drawer>
            </ListItem>

            <ListItem key={'Saved Pins'}>
                <ListItemButton onClick={handlePinListOpen}>Saved Pins</ListItemButton>

{isMobile &&
            <Drawer 
            size='md'
            sx={{ ml:2 /*'min(100vw, var(--Drawer-horizontalSize))'*/ }}
            open={pinOpen}
            anchor='left'
                onClose={handlePinListNav}>


                <Stack alignContent={'left'} sx={{marginLeft:'8px'}}>
                <Box py={0.5}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                    <img className='smallReturn' src={leftArrow} alt='return' onClick={returnToMainNav}/>
                        <Typography level='h4'>Saved Pins</Typography>
                    </Stack>
                </Box>
                <ModalClose size='sm' />
                </Stack>
                <Divider />
                <PinListDisplay closeAll={handlePinListNav}/>

            </Drawer>
            }
            </ListItem>
        {isAdmin
        ?
        <ListItem key={'AdminAccess'}>
            <ListItemButton onClick={handleAdminNav}>Admin Access</ListItemButton>
        </ListItem>
        :
        <></>}
        </List>
        <Divider />
        <List>
            {[ 'Donate to ULLR', 'Purchase Prints', 'Contact'].map((text) => (
            <ListItem key={text}>
                <ListItemButton>{text}</ListItemButton>
            </ListItem>
            ))}
        </List>

        <Button variant='outlined' color='neutral' sx={{width:'100px', margin:'5px'}} onClick={handleSignOut}>Sign Out</Button>
        </Stack>
      </Drawer>


{openFA
? 
<Box
sx={{
    position: 'fixed',
    top: '70px',
    right:  '10px',
    minWidth: 250,
    width: isMobile ? '40%' : '',
    bgcolor: 'white',
    borderRadius: 5,
    boxShadow: 3,
    p: 3,
    zIndex: 1006,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:isMobile? 1:2
  }}
>
    <Stack spacing={1}>

        {userPosition.latitude !=null
        ?
<Stack alignItems='center'>
    <Stack direction='row' alignItems='center' p={0} m={-1}>
        <Button variant='plain' color='neutral'
            onClick={updateUserPosition}
            loading={loading} 
            sx={{width:'15px',height:'15px',p:0, marginRight:'7px'}}>
                <img src={refresh} style={{height:'10px'}}/>
        </Button>
        <Typography level='body-xs'>Current Coordinates:</Typography>
    </Stack>
    <Button onClick={handleCoordinates} variant='plain' color='neutral'>
        <Stack direction='row' spacing={1}>
            <Typography>{userPosition.latitude.toFixed(3)}</Typography>
            <Typography>{userPosition.longitude.toFixed(3)}</Typography>
        </Stack>
    </Button>
    <Stack>
        <Typography>Approximate Elevation: {userPosition.elevation.toFixed(0)}m</Typography>
    </Stack>

</Stack>
:
<Stack>
    
    <Button onClick={updateUserPosition} loading={loading} variant='plain' color='neutral'><Typography>{loading? '':'Find your position'}</Typography></Button>
</Stack>
}
<Stack alignItems='center' spacing={0}>

<Typography level='body-xs'>Whistler Blackcomb Ski Patrol #</Typography>
{isMobile
?
<a href={`tel:${phoneNumber}`}>
<Button variant='plain' color='neutral'>604-935-5555</Button>
</a>
:
<>
<Button onClick={handleCopy} variant='plain' color='neutral'>604-935-5555</Button>
</>
}
<Snackbar size='sm'open={copied} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal:'right'}}
    sx={{maxWidth:'20px', marginTop:'70px', marginright:'10px'}}>Copied!</Snackbar>
    </Stack>
</Stack>
</Box>
:
<></>
}
{isBrowser && pinOpen &&

<Box
  sx={{
    position: 'fixed', // Fix it to the screen
    top: 0,            // Start from the top
    left: 0,           // Align to the left of the screen
    width: '350px',    // Set the width to 200px
    height: '100%',    // Make the height fill the entire screen
    zIndex: 1010,      // Ensure it's on top of other elements
    backgroundColor: 'white', // Optional: add a background color
    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // Optional: slight shadow for better visibility
    overflowY: 'auto',
  }}
  open={pinOpen}
  anchor="left"
  onClose={handlePinListNav}
>
    <Stack alignContent={'left'} sx={{marginLeft:'8px'}}>
    <Box py={isMobile?0.5:1}>
        <Stack direction='row' spacing={2} alignItems='center'>
        <img className='smallReturn' src={leftArrow} alt='return' onClick={returnToMainNav}/>
            <Typography level='h4'>Saved Pins</Typography>
        </Stack>
    </Box>
    <ModalClose size='sm' onClick={handlePinListNav}  />
    </Stack>
    <Divider />
    <PinListDisplay closeAll={handlePinListNav}/>

            </Box>
}
    </Box>
  );
}
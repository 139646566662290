import React, { createContext, useState, useContext } from 'react';

// Create a context for the position
const PositionContext = createContext();

// Create a provider component
export const PositionProvider = ({ children }) => {
  const [position, setPosition] = useState({ latitude: null, longitude: null, zoom: null, title:null });
  const [userPosition, setUserPosition] = useState({ latitude: null, longitude: null, zoom: null, elevation:null });
  return (
    <PositionContext.Provider value={{ position, setPosition, userPosition, setUserPosition  }}>
      {children}
    </PositionContext.Provider>
  );
};

// Custom hook to use the position context
export const usePosition = () => {
  return useContext(PositionContext);
};

// functions for pin creation,fetching and updating
import { getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createEntry, updateEntry, deleteEntry } from '../../graphql/mutations';
import { listEntries } from '../../graphql/queries';

const client = generateClient();

// new pin creation
const createNewPin = async (coordinatesInput, titleInput, descriptionInput) => {
  try {
    const user = await getCurrentUser()
    //console.log(user)
    const userID= user.username

    //console.log(`Creating new pin coords ${coordinatesInput.lat} ${coordinatesInput.lng}, title ${titleInput}, desc ${descriptionInput}`);

    const QueryInput= {
      lat:coordinatesInput.lat.toString(),
      lng:coordinatesInput.lng.toString(),
      title: titleInput,
      description: descriptionInput,
      owner:userID
    }
    //console.log(QueryInput)

    // Perform GraphQL mutation with required variables
    const { result } = await client.graphql({
      query: createEntry, // Ensure that createEntry is correctly imported or defined
      variables: {
        input: QueryInput
      },

    });

    console.log('pin created' );
  } catch (error) {
    console.error('Error creating pin:', error);
  }
};

//fetch pin list
const fetchPinList = async() => {
  try {
    console.log('fetching pinnnnns')
      const results = await client.graphql({ query: listEntries });
      return results  
  } catch (error) {
    console.error( error);
  }
}

//update pin
const updatePin =async(coordinatesInput, titleInput, descriptionInput, userID) => {

  try {
    const updatedEntry = await client.graphql({
      query: updateEntry,
      variables: {
          input: {
            lat:coordinatesInput.lat.toString(),
            lng:coordinatesInput.lng.toString(),
            title: titleInput,
            description: descriptionInput,
            owner:userID
          }
      }
    });
    console.log('update successful')
  } catch (error) {
    console.log('error updating pin', error)
  }
}

//delete pin
const deletePin = async(inputID) => {
  try {
    const deletedEntry = await client.graphql({
      query: deleteEntry,
      variables: {
          input: {
              id: inputID
          }
      }
    });
    console.log('deletion successful')
  } catch (error) {
    console.log('error deleting pin', error)
  }
}


export {createNewPin, updatePin, deletePin, fetchPinList}

import React, { useState, useEffect, useRef, useCallback  } from 'react';
import { CSSTransition } from 'react-transition-group';


import largeLogo from '../assets/ULLRLogo.svg'
import { version, preVersion } from '../utils/localStorage/version';
import '../css/navbar.css';
import '../css/transitions.css';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';



const SplashPage = ({ onSplashEnd, setMainDisplay}) => {
  // State to control the visibility of the component
  const [showSplash, setShowSplash] = useState(true);

  // Display component for 2 seconds and then hide it
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
      onSplashEnd();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onSplashEnd]);


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CSSTransition in={showSplash} timeout={2000} className='fade-in'>
      <div className="splash-container">
        <Box>
          <Stack direction="column" justifyContent="center" alignItems="center">
          <img className='splashLogo' src={largeLogo}/>
            <Typography level="title-lg">{preVersion} {version}</Typography>
          </Stack>
        </Box>
      </div>
    </CSSTransition>
    </div>
  );
};

export default SplashPage;

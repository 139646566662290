import React, {useState} from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { version, preVersion } from './localStorage/version';

export default function Footer() {

    return (
        <Box sx={{
            position: 'fixed',
            zIndex:1000,
            backgroundColor:'white',
            opacity:0.5,
            left: '0',
            bottom: '0',
            width: '100%',
        }}>
            <Typography textAlign='center'>Ullr Maps {preVersion} {version}</Typography>
        </Box>
    )
}
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography, Card, Stack, Box, AccordionGroup, Divider } from '@mui/joy';
import { isMobile, } from 'react-device-detect';
import { usePosition } from '../MapUtils/MapContext/PositionContext';
import { fetchPinList, updatePin, deletePin } from '../userDataFetching/pinFetching';
import { PinDeletionModal, PinUpdateModal } from '../Modals/pinModals';
import { ToggleMultiplePins } from './pinLocationFinding';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext';


import refresh from '../../assets/refresh.png'
import '../../css/navbar.css'

const PinListDisplay = ({ closeAll }) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [cardIndex, setCardIndex] = useState(null);
    const [refreshLoading, setRefreshLoading] = useState(false)
    const { setPosition} = usePosition();
    const { addPin, removePin, pinListPositions, setFocusedPin } = usePinListPosition();

    const handleDeleteOpen = () =>{
      setOpenDelete(true)
    };
    const handleDeleteClose = () => setOpenDelete(false);

    const handleUpdateOpen = () =>{
      setOpenUpdate(true)
    };
    const handleUpdateClose = () => setOpenUpdate(false);

    const handleFetchPinList= async() => {
        try {
            console.log('fetching pin list')
            const {data} = await fetchPinList()
            console.log('fetched pins',data.listEntries.items)
            data.listEntries.items.forEach(({ lat, lng, title, description, id }) => {
              addPin(lat, lng, title, description, id);
            });
            console.log('refresh done')
            setRefreshLoading(false)
            
        } catch (error) {
            console.log('error fetching pins', error)
        }
    }

    const refreshPinList = () => {
      if (pinListPositions.length > 0) {
        console.log('refresh start')
        setRefreshLoading(true)
        pinListPositions.forEach(({title})=>{
          removePin(title)
        })
        
        handleFetchPinList()
      } else {
        handleFetchPinList()
      }
    }
    
    useEffect(() => {
      if (pinListPositions.length === 0) { // Ensuring it fetches only if the list is empty
        refreshPinList();
      }
    }, [])

    const handleDeletePin =async(IDInput)=> {
      //console.log(IDInput)
      await deletePin(IDInput).then(
        refreshPinList()
      )
      setCardIndex(null)
    }

    const handleUpdatePin=()=> {
      console.log('updated pin')
    }

    const handleGoToPoint = (input) => {
      //console.log({ input });
      if(isMobile) {
        closeAll();
      }
      // Set position directly using hook setter
      setFocusedPin(input)
    };

    

if (pinListPositions?.length === 0 || pinListPositions=== undefined) {
    return (
      <>
      { refreshLoading
      ?
      <Card>
        <Button loading></Button>
      </Card>
        :
        <Stack height='100%' justifyContent='space-between'>
        <Card style={{ margin: '20px' }}>
        
            <Typography variant="h6" component="div">
            No Pins Available
            </Typography>
            <Typography variant="body2">
            Create some pins to display them here.
            </Typography>
        </Card>
            <Button variant='outlined' color='neutral' onClick={refreshPinList} sx={{marginBottom:'30px', width:'50%', alignSelf:'center'}}>
            <img className='refreshButton' src={refresh} alt='refresh'></img>
            </Button>
            </Stack>
          }
          </>
    );
    }

  return (
    <Stack  display="flex" justifyContent="space-between" alignItems="center" height={isMobile?'100%':'92%'} sx={{width:'100%'}}>
      
<AccordionGroup sx={{width:'95%', marginTop:'4px'}}>
  <Stack spacing={0.5}>
      {pinListPositions.map((pin, index) => (
        <Accordion  key={index}
          expanded={index === cardIndex}
          onChange={(event, expanded) => {
            setCardIndex(expanded ? index : null);
          }}>

          <AccordionSummary >
            <Typography height='30px' level='body-lg' fontWeight='bold'>{pin.title}</Typography>
          </AccordionSummary>
          
          <AccordionDetails>
          
            <Stack spacing={1} marginTop='10px'>

            {pin.description && (
              <Typography>
                <strong>Description:</strong> {pin.description}
              </Typography>
            )}
            <Typography>
              <strong>Coordinates:</strong> {`Lat: ${parseFloat(pin.latitude).toFixed(3)}, Lng: ${parseFloat(pin.longitude).toFixed(3)}`}
            </Typography>
            <Stack spacing={1} direction='row'>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => handleGoToPoint(index)}
                >
                View
              </Button>
              <PinDeletionModal handleDeleteOpen={handleDeleteOpen} handleDeleteClose={handleDeleteClose} inputID={pin} handleDeletePin={handleDeletePin} openDelete={openDelete}/>
              {/*}
              <PinUpdateModal handleUpdateOpen={handleUpdateOpen} handleUpdateClose={handleUpdateClose} inputData={pin} handleUpdatePin={handleUpdatePin} openUpdate={openUpdate}/>
              */}
                </Stack>
            </Stack>
          </AccordionDetails>

        </Accordion>


      ))}
      </Stack>
      </AccordionGroup>
      
      <Stack direction='row'spacing={1} marginBottom={1} padding={2}>
        <ToggleMultiplePins coordinates={pinListPositions} closeAll={closeAll}/>
        <Button variant='outlined' color='neutral' onClick={()=>refreshPinList()} sx={{marginBottom:'30px', alignSelf:'center'}}>
          <img className='refreshButton' src={refresh} alt='refresh'></img>
        </Button>
      </Stack>
      
    </Stack>
  );
};

export default PinListDisplay;
